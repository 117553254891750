import { initialsAvatar } from "./initialsAvatar";

// to ensure consistent FE/BE-rendering of user avatars
window.accountAvatar = function (account, options = {}) {
  const css_class = `${
    options.class || "h-7 w-7"
  } rounded overflow-hidden shrink-0`;

  if (account.avatar) {
    return `<img title="${account.name}" src="${account.avatar}" class="${css_class}" />`;
  }

  return initialsAvatar(account, css_class);
};
