import Sortable from "sortablejs";
import { pushNotification } from "../../pushNotification";
import { addCurrentPageEventListener } from "../../../shared/utils";

export function studiesSurveySetup(args) {
  return {
    study: JSON.parse(JSON.stringify(args.study)), // Deep clone object so we can check if form is dirty later
    studyBatch: args.studyBatch,
    sender: args.sender,
    questionModalOpen: false,
    copyExistingSurveyOpen: false,
    editingQuestionIdx: null,
    questionFormData: {},
    init() {
      this.initSortable("question-sortable-list");

      addCurrentPageEventListener("turbo:before-visit", (event) => {
        const isSubmitting = event.detail?.url?.includes("setup/review");
        if (isSubmitting) return;

        if (this.isDirty() && !confirm("You have unsaved changes!")) {
          event.preventDefault();
        }
      });

      addCurrentPageEventListener("dx:copy-existing-survey-questions", (e) => {
        this.study.questions = e.detail.questions;
        this.copyExistingSurveyOpen = false;
      });
    },
    isDirty() {
      return JSON.stringify(args.study) !== JSON.stringify(this.study);
    },
    addQuestion(questionType) {
      this.editingQuestionIdx = null;

      this.questionFormData = {
        type: questionType,
        required: true,
        options: [],
      };

      if (questionType === "multiple_choice") {
        this.questionFormData.options = [
          { name: "Option A" },
          { name: "Option B" },
        ];
      } else if (questionType === "rating_scale") {
        this.questionFormData.options = [
          { name: "Bad" },
          { name: "So-so" },
          { name: "Good" },
        ];
      } else if (questionType === "multi_select") {
        this.questionFormData.optional = true;
        this.questionFormData.options = [
          { name: "Option A" },
          { name: "Option B" },
        ];
      }

      this.questionModalOpen = true;

      this.$nextTick(() => {
        this.initSortable("modal-sortable-list", this.questionFormData.options);
      });
    },
    get isValid() {
      return (
        !!this.studyBatch.message_text.trim() &&
        !this.studyBatch.message_text.includes("[TOPIC]") &&
        this.study.questions.length <= 12 &&
        this.study.questions.length > 0
      );
    },
    removeQuestion(idx) {
      this.study.questions.splice(idx, 1);
    },
    editQuestion(question, idx) {
      this.questionFormData = JSON.parse(JSON.stringify(question)); // Deep clone question so we don't mutate object
      this.editingQuestionIdx = idx;
      this.questionModalOpen = true;

      this.$nextTick(() => {
        this.initSortable("modal-sortable-list", this.questionFormData.options);
      });
    },
    duplicateQuestion(question, idx) {
      const questionCopy = JSON.parse(JSON.stringify(question)); // Deep clone question so we don't mutate object
      this.study.questions.splice(idx, 0, questionCopy);
    },
    changeSender(currentUser) {
      this.sender = currentUser;
    },
    sendTest() {
      fetch(args.testEndpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          questions: this.study.questions,
          message_text: this.studyBatch.message_text,
        }),
      });

      pushNotification({
        title: "Success!",
        desc: `Check ${args.integrationName} for a test message.`,
      });
    },
    get atMaxQuestions() {
      return this.study.questions.length >= 12;
    },

    // Stuff for question modal
    get editing() {
      return this.editingQuestionIdx !== null;
    },
    handleSubmit(e) {
      e.preventDefault();

      if (this.editing) {
        this.study.questions[this.editingQuestionIdx] = this.questionFormData;
      } else {
        this.study.questions.push(this.questionFormData);
      }

      this.questionModalOpen = false;
    },
    isDuplicate(name) {
      const { options } = this.questionFormData;
      return options.filter((o) => o.name === name).length > 1;
    },
    addOption() {
      const { options } = this.questionFormData;
      options.push({ name: "" });

      this.$nextTick(() => {
        this.$refs.newOption.focus();
      });
    },
    removeOption(idx) {
      const { options } = this.questionFormData;
      options.splice(idx, 1);
    },
    get questionModalIsValid() {
      const { options, prompt, type } = this.questionFormData;

      const validPrompt = !!prompt?.trim()?.length;

      let validOptions = true;
      if (["multiple_choice", "rating_scale"].includes(type)) {
        validOptions =
          options.length >= 2 &&
          options.every((i) => {
            return (
              i.name !== undefined &&
              i.name !== null &&
              !!i.name?.trim()?.length
            );
          }) &&
          !options.some((item) => this.isDuplicate(item.name));
      }

      return validPrompt && validOptions;
    },

    // This handles sorting for the list of questions AND list of options when editing a question
    reorderArray(array, order) {
      const newArray = new Array(array.length);
      order.forEach((newIndex, originalIndex) => {
        newArray[newIndex] = array[originalIndex];
      });

      return newArray;
    },
    initSortable(listId) {
      const self = this;
      const sortableList = document.getElementById(listId);

      if (!sortableList) return;

      const editableContents =
        sortableList.querySelectorAll(".editable-content");

      new Sortable(sortableList, {
        animation: 150,
        handle: ".handle",
        onStart() {
          editableContents.forEach((content) =>
            content.setAttribute("contenteditable", "false"),
          );
        },
        onEnd() {
          editableContents.forEach((content) =>
            content.setAttribute("contenteditable", "true"),
          );

          const order = Array.from(
            sortableList.querySelectorAll(".sortable-item"),
          )
            .map((item) => item.dataset.idx)
            .map(Number);

          const toRemove = sortableList.querySelectorAll(".sortable-item");

          // Remove dom elements
          toRemove.forEach((item) => sortableList.removeChild(item));

          // Add dom elements
          const toAdd = self.reorderArray(toRemove, order);
          toAdd.forEach((item) => sortableList.appendChild(item));

          // Update component data
          if (listId == "modal-sortable-list") {
            self.questionFormData.options = order.map(
              (idx) => self.questionFormData.options[idx],
            );
          } else {
            self.study.questions = order.map(
              (idx) => self.study.questions[idx],
            );
          }
        },
      });
    },
  };
}
