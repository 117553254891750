import { addCurrentPageEventListener } from "../../shared/utils";

function transformArray(arr) {
  // Create a new map of objects with 'children' property
  let itemMap = arr.reduce((acc, { id, name, parent_id }) => {
    acc[id] = { id, name, parent_id, children: [] };
    return acc;
  }, {});
  let topLevel = [];

  // Populate 'children' arrays
  arr.forEach((item) => {
    if (item.parent_id !== null && itemMap[item.parent_id]) {
      itemMap[item.parent_id].children.push(item.id);
    } else {
      topLevel.push(item.id);
    }
  });

  return { itemMap, topLevel };
}

export function areaSelect(args) {
  const CONTAINER_WIDTH = 48,
    COLUMN_WIDTH = 20;

  let { areas, parents } = args;
  const { selected, onChange, listenFor } = args;

  let lastSelected = null;

  return {
    selected,
    selections: [],
    shown: false,
    selectedFolder: null,
    areaSet: {},
    highlighted: null,
    topSet: [],
    onChange,
    columns: 0, // use a value so that the template gets updated using two-way binding
    componentDisabled: args.componentDisabled,
    init() {
      if (selected) {
        this.collectSelections();
      }

      this.$root.addEventListener("dx:squadsTree:init", (e) => {
        const { itemMap, topLevel } = transformArray(e.detail);
        areas = itemMap;
        parents = topLevel;
        this.collectSelections();
        this.columns = this.columnCount();
        this.areaSet = itemMap;
        this.topSet = topLevel;
      });

      if (listenFor) {
        addCurrentPageEventListener(listenFor, (event) => {
          this.setSelected(event.detail);
        });
      }
    },
    get areas() {
      return areas;
    },
    get containerWidth() {
      return CONTAINER_WIDTH;
    },
    get columnWidth() {
      return COLUMN_WIDTH;
    },
    get showScrollbar() {
      return this.columns * COLUMN_WIDTH > CONTAINER_WIDTH;
    },
    columnCount() {
      let depth = this.selections?.length || 1;

      const children = this.childrenOf(depth);

      // Render additional column if any of the children are expandable
      for (var i = 0; i < children.length; i++) {
        if (this.hasChildren(children[i])) {
          depth++;
          break;
        }
      }

      return depth;
    },
    navigateUp() {
      this.selectedFolder = this.areaSet[this.selectedFolder.parent_id];
    },
    selectItemOnMobile(squadId) {
      if (this.hasChildren(squadId)) {
        this.selectedFolder = this.areaSet[squadId];
        this.highlighted = null;
      } else {
        this.highlighted = squadId;
      }
    },
    selectSquadOnMobile() {
      this.selected = this.highlighted
        ? this.highlighted
        : this.selectedFolder.id;
      this.shown = false;

      this.$nextTick(() => {
        onChange(this.$root, areas[this.selected]);
      });
    },
    clearParentSquad() {
      this.selected = null;
      this.parents = [];
    },
    collectSelections() {
      this.selections = [];

      const selectedArea = areas[this.selected];

      if (!selectedArea) return;

      this.selections.push(this.selected);

      let currentParentId = selectedArea["parent_id"];

      while (currentParentId !== null) {
        this.selections.unshift(currentParentId);
        currentParentId = areas[currentParentId]["parent_id"];
      }
    },
    childrenOf(nodeIndex) {
      const index = nodeIndex - 1;
      if (index === 0) return parents;

      const currentSelection = areas[this.selections[index - 1]];
      if (currentSelection) return currentSelection["children"];

      return [];
    },
    toggle() {
      lastSelected = this.selected;

      this.shown = !this.shown;
    },
    hasChildren(item) {
      return areas[item]["children"].length > 0;
    },
    isSelected(item) {
      return this.selected === item;
    },
    isParentOfSelected(item, depth) {
      if (this.isSelected(item)) return false;

      return this.selections[depth - 1] === item;
    },
    cancel() {
      this.selected = lastSelected;
      this.shown = false;
      this.columns = this.columnCount();
      this.collectSelections();
    },
    assign(item, depth) {
      this.selections = [...this.selections.slice(0, depth), item];
      this.selected = item;
      this.columns = this.columnCount();
    },
    setSelected(item) {
      this.selected = item.id;
      this.collectSelections();
    },
  };
}
