export function flaggedComments(props) {
  return {
    snapshotId: props.snapshotId,
    showFlaggedCommentsModal: false,
    isLoading: false,
    selectedComments: new Set(),

    init() {
      if (!this.$store.flaggedComments) {
        this.$store.flaggedComments = [];
      }
      this.fetchFlaggedComments();
    },

    async fetchFlaggedComments() {
      this.isLoading = true;
      try {
        const response = await fetch(
          `/snapshots/v2/${this.snapshotId}/sentiment/flagged_comments`,
        );
        const data = await response.json();
        this.$store.flaggedComments = data.items;
      } catch (error) {
        console.error("Error fetching flagged comments:", error);
      } finally {
        this.isLoading = false;
      }
    },

    openModal() {
      this.showFlaggedCommentsModal = true;
      this.fetchFlaggedComments();
    },

    closeModal() {
      this.showFlaggedCommentsModal = false;
      this.selectedComments.clear();
    },

    toggleCommentSelection(comment) {
      if (this.selectedComments.has(comment.encoded_id)) {
        this.selectedComments.delete(comment.encoded_id);
      } else {
        this.selectedComments.add(comment.encoded_id);
      }
    },

    isCommentSelected(commentId) {
      return this.selectedComments.has(commentId);
    },

    async saveChanges() {
      if (this.selectedComments.size === 0) {
        this.closeModal();
        return;
      }

      try {
        await Promise.all(
          Array.from(this.selectedComments).map((commentId) =>
            fetch(`/snapshot_response/${commentId}/unflag`, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
            }),
          ),
        );

        this.$store.flaggedComments = this.$store.flaggedComments.filter(
          (comment) => !this.selectedComments.has(comment.encoded_id),
        );

        window.pushNotification({
          title: "Changes saved",
          desc: "The selected comments have been unflagged.",
        });

        this.closeModal();
      } catch (error) {
        console.error("Error unflagging comments:", error);
        window.pushNotification({
          title: "Error",
          desc: "Failed to unflag comments. Please try again.",
          type: "error",
        });
      }
    },
  };
}
