export function snapshotResultsNav() {
  return {
    init() {
      document.addEventListener("react-state-update", (event) => {
        this.$store.snapshotResultFilters.branch = event.detail.branch;
      });
    },
    get squadIds() {
      return this.$store.snapshotResultFilters.squadIds;
    },
    get tagIds() {
      return this.$store.snapshotResultFilters.tagIds;
    },
    get compareTo() {
      return this.$store.snapshotResultFilters.compareTo;
    },
    get previousSnapshotId() {
      return this.$store.snapshotResultFilters.previousSnapshotId;
    },
    get branch() {
      return this.$store.snapshotResultFilters.branch;
    },
    queryParams() {
      let string = "";

      if (this.squadIds.length > 0) {
        string += `?squad_ids=${this.squadIds.join(",")}`;
      }

      if (this.tagIds.length > 0) {
        if (string.includes("?")) {
          string += "&";
        } else {
          string += "?";
        }

        string += `tag_ids=${this.tagIds.join(",")}`;
      }

      if (this.compareTo != "vs_50th") {
        if (string.includes("?")) {
          string += "&";
        } else {
          string += "?";
        }

        string += `ct=${this.compareTo}`;
      }

      if (this.previousSnapshotId) {
        if (string.includes("?")) {
          string += "&";
        } else {
          string += "?";
        }
        string += `prev_ss=${this.previousSnapshotId}`;
      }

      if (this.branch) {
        if (string.includes("?")) {
          string += "&";
        } else {
          string += "?";
        }
        string += `branch=${this.branch}`;
      }

      return string;
    },
    clearFilters() {
      this.$store.snapshotResultFilters.squadIds = [];
      this.$store.snapshotResultFilters.tagIds = [];

      return "";
    },
  };
}
