import {
  objectToQueryString,
  setQueryStringParam,
  getQueryStringParams,
  deleteQueryStringParam,
  snapshotSquadsFromCache,
  groupBy,
} from "../../shared/utils";
import tippy from "tippy.js";

const _SS_ALLOC_COLOR_ORDER = [
  "rgb(129, 140, 248)", // Indigo 400
  "rgb(96, 165, 250)", // Blue 400
  "rgb(56, 189, 248)", // Sky 400
  "rgb(34, 211, 238)", // Cyan 400
  "rgb(45, 212, 191)", // Teal 400
  "rgb(52, 211, 153)", // Emerald 400
  "rgb(74, 222, 128)", // Green 400
  "rgb(163, 230, 53)", // Lime 400
  "rgb(250, 204, 21)", // Yellow 400
  "rgb(251, 146, 60)", // Orange 400
  "rgb(248, 113, 113)", // Red 400
  "rgb(251, 113, 133)", // Rose 400
  "rgb(244, 114, 182)", // Pink 400
  "rgb(232, 121, 249)", // Fuchsia 400
  "rgb(167, 139, 250)", // Violet 400
  "rgb(192, 132, 252)", // Purple 400
];
const _SS_ALLOC_COLOR_OTHER = "rgb(156, 163, 175)"; // Gray 400

export function snapshotAllocationResult(args) {
  const {
    questionId,
    isCoreQuestion,
    summaryEndpoint,
    breakdownEndpoint,
    questionItemOverrides,
    tagOptions,
    snapshotId,
  } = args;

  return {
    isCoreQuestion,
    summaryData: {},
    chartData: [],
    comparisonChartData: [],
    colors: {}, // Shared b/w charts and breakdown
    summaryLoaded: false,
    summaryUpdating: false,
    breakdownData: [],
    breakdownOpen: getQueryStringParams().get("question") == questionId,
    breakdownLoaded: false,
    breakdownUpdating: false,
    tableSort: { col: "group_name", dir: "asc" },
    squadNamesFromSquadIds: [],
    init() {
      this.fetchSummary();
      if (this.breakdownOpen) this.openBreakdown();

      this.$watch("$store.snapshotResultFilters.hierarchyGroup", () => {
        if (this.breakdownOpen && this.breakdownLoaded) {
          // Only for refetching after initial load
          this.fetchBreakdown();
        }
      });

      this.$watch(
        "$store.snapshotResultFilters.compareTo, $store.snapshotResultFilters.previousSnapshotId",
        () => {
          if (this.compareTo == "vs_prev" || this.compareTo == "vs_org")
            this.fetchSummary();
          this.processData();
        },
      );
    },
    fetchSummary() {
      const queryString = objectToQueryString({
        squad_ids: this.squadIds.join(","),
        tag_ids: this.tagIds.join(","),
        branch: this.$store.snapshotResultFilters.branch,
        ct: this.compareTo,
        prev_ss: this.previousSnapshotId,
      });

      this.summaryUpdating = this.summaryLoaded;

      fetch(`${summaryEndpoint}?${queryString}`)
        .then((response) => response.json())
        .then((data) => {
          this.summaryData = data;
          this.processData();
          this.summaryLoaded = true;
          this.summaryUpdating = false;
        });
    },
    processData() {
      this.chartData = [];
      this.comparisonChartData = [];

      if (
        isCoreQuestion &&
        ["vs_50th", "vs_75th", "vs_90th"].includes(this.compareTo)
      ) {
        this.processCoreQuestionData();
      } else {
        this.summaryData.results.forEach((item, idx) => {
          const color =
            item.name === "Other"
              ? _SS_ALLOC_COLOR_OTHER
              : _SS_ALLOC_COLOR_ORDER[idx];

          this.colors[item.id] = color;

          this.chartData.push(
            Object.assign({}, item, { name: this.questionItemLabel(item) }),
          );
        });
      }

      this.generateComparisonChartData();
    },
    processCoreQuestionData() {
      const bucketedData = groupBy(this.summaryData.results, "bucket");

      const innovation = {
        id: "innovation",
        name: "New capabilities",
        pct: 0,
        tooltip: this.chartTooltip(bucketedData["innovation"]),
      };
      this.colors["innovation"] = _SS_ALLOC_COLOR_ORDER[0];
      bucketedData["innovation"]?.forEach((item) => {
        innovation.pct += item.pct;
        this.colors[item.id] = this.colors["innovation"];
      });

      const ktlo = {
        id: "ktlo",
        name: "KTLO and maintenance",
        pct: 0,
        tooltip: this.chartTooltip(bucketedData["ktlo"]),
      };
      this.colors["ktlo"] = _SS_ALLOC_COLOR_ORDER[1];
      bucketedData["ktlo"]?.forEach((item) => {
        ktlo.pct += item.pct;
        this.colors[item.id] = this.colors["ktlo"];
      });

      const other = {
        id: "other",
        name: "Other",
        pct: 0,
        tooltip: this.chartTooltip(bucketedData[null]),
      };
      this.colors["other"] = _SS_ALLOC_COLOR_OTHER;
      bucketedData[null]?.forEach((item) => {
        other.pct += item.pct;
        this.colors[item.id] = this.colors["other"];
      });

      this.chartData.push(innovation);
      this.chartData.push(ktlo);
      this.chartData.push(other);
    },
    generateComparisonChartData() {
      if (this.compareTo == "vs_prev" || this.compareTo == "vs_org") {
        this.summaryData.comparison_results.forEach((item) => {
          this.comparisonChartData.push(
            Object.assign({}, item, { name: this.questionItemLabel(item) }),
          );
        });
      } else {
        if (!this.summaryData.industry_benchmarks) return;

        let percentile = "p_50";
        if (this.compareTo == "vs_75th") percentile = "p_75";
        if (this.compareTo == "vs_90th") percentile = "p_90";

        const benchmarks = this.summaryData.industry_benchmarks;
        const innovation = benchmarks["allocation_self_report"]?.[percentile];

        // TODO: REPLACE WITH ACTUAL BENCHMARK
        const ktlo =
          benchmarks["allocation_ktlo_self_report"]?.[percentile] || 25;
        const other =
          100 - parseFloat(innovation).toFixed(1) - parseFloat(ktlo).toFixed(1);

        if (innovation)
          this.comparisonChartData.push({
            id: "innovation",
            pct: parseFloat(innovation).toFixed(1),
          });

        if (ktlo)
          this.comparisonChartData.push({
            id: "ktlo",
            pct: parseFloat(ktlo).toFixed(1),
          });

        if (other)
          this.comparisonChartData.push({
            id: "other",
            pct: parseFloat(other).toFixed(1),
          });
      }
    },
    fetchBreakdown() {
      this.breakdownUpdating = this.breakdownLoaded;

      const queryString = objectToQueryString({
        squad_ids: this.squadIds.join(","),
        tag_ids: this.tagIds.join(","),
        branch: this.$store.snapshotResultFilters.branch,
        hg: this.$store.snapshotResultFilters.hierarchyGroup?.encoded_id,
        atg: this.$store.snapshotResultFilters.aggregationTagGroup?.encoded_id,
      });

      fetch(`${breakdownEndpoint}?${queryString}`)
        .then((response) => response.json())
        .then((data) => {
          this.breakdownData = data.results;
          this.breakdownLoaded = true;
          this.breakdownUpdating = false;
        });
    },
    openBreakdown() {
      this.determineSquadNamesFromSquadIds();
      this.breakdownOpen = true;
      setQueryStringParam("question", questionId);
      this.fetchBreakdown();
    },
    closeBreakdown() {
      this.breakdownOpen = false;
      deleteQueryStringParam("question");
    },
    get sortedBreakdown() {
      let list = this.breakdownData;

      const isDesc = this.tableSort.dir === "desc";

      if (this.tableSort.col === "group_name") {
        list = list.sort((a, b) => {
          return isDesc
            ? b.group_name.localeCompare(a.group_name)
            : a.group_name.localeCompare(b.group_name);
        });
      } else if (this.tableSort.col === "sample_size") {
        list = list.sort((a, b) => {
          const aSize = a["sample_size"] || 0;
          const bSize = b["sample_size"] || 0;
          return isDesc ? bSize - aSize : aSize - bSize;
        });
      } else {
        list = list.sort((a, b) => {
          const aVal = a.items[this.tableSort.col];
          const bVal = b.items[this.tableSort.col];
          // Sort by numeric values, then hidden responses always after values, then nulls always last
          if (aVal == null) return 1;
          if (bVal == null) return -1;
          if (aVal == "hidden" && bVal != "hidden") return 1;
          if (bVal == "hidden" && aVal != "hidden") return -1;
          return isDesc ? bVal - aVal : aVal - bVal;
        });
      }

      return list;
    },
    changeSquadIds(squadIds) {
      this.$store.snapshotResultFilters.squadIds = squadIds;
      setQueryStringParam("squad_ids", squadIds);

      this.fetchSummary();
    },
    changeTagIds(tagIds) {
      this.$store.snapshotResultFilters.tagIds = tagIds;
      setQueryStringParam("tag_ids", tagIds);

      this.fetchSummary();
    },
    questionItemLabel(item) {
      return questionItemOverrides[item.id] || item.name;
    },
    get groupTitle() {
      return this.sortedBreakdown[0]?.group_title || "Team";
    },
    get squadIds() {
      return this.$store.snapshotResultFilters.squadIds;
    },
    get tagIds() {
      return this.$store.snapshotResultFilters.tagIds;
    },
    get compareTo() {
      return this.$store.snapshotResultFilters.compareTo;
    },
    get comparingToIndustry() {
      return ["vs_50th", "vs_75th", "vs_90th"].includes(this.compareTo);
    },
    get previousSnapshotId() {
      return this.$store.snapshotResultFilters.previousSnapshotId;
    },
    // This exists in all breakdowns to display the "Showing N teams and N attributes" context
    get tagNamesFromTagIds() {
      const selectedTags = tagOptions.filter(
        (tag) =>
          this.tagIds.includes(String(tag.id)) || this.tagIds.includes(tag.id),
      );

      return selectedTags.map((tag) => tag.name);
    },
    // This exists in all breakdowns to display the "Showing N teams and N attributes" context
    determineSquadNamesFromSquadIds() {
      const cachedSquads = snapshotSquadsFromCache(snapshotId);
      const selectedSquads = cachedSquads.filter(
        (squad) =>
          this.squadIds.includes(String(squad.id)) ||
          this.squadIds.includes(squad.id),
      );
      this.squadNamesFromSquadIds = selectedSquads.map((squad) => squad.name);
    },
    chartTooltip(items) {
      if (!items) return;

      const list = items.map((item) => {
        return `<div class='flex items-center justify-between py-0.5'><span>${this.questionItemLabel(item)}</span> ${item.pct}%</div>`;
      });
      return `<div class='w-80 p-2 text-sm bg-white text-left'>
          <div class='text-xs text-gray-500 mb-3'>
            Survey items
          </div>
          <div class='mt-1 text-gray-700 text-tiny font-medium'>
            ${list.join("")}
          </div>
        </div>`;
    },
    tooltipIfTruncated(e, fullText) {
      const el = e.target;

      if (el && el.offsetWidth < el.scrollWidth) {
        tippy(el, {
          content: fullText,
          delay: [250, 0],
        });
      }
    },
    sortableHeader(column) {
      const isCurrentSort = column === this.tableSort.col;
      const isAsc = this.tableSort.dir === "asc";

      return `
        <div class="text-gray-300 flex flex-col -space-y-1.5 text-xs -mb-px">
          <i class="-mb-px bx bx-caret-up" :class="{'text-gray-500': ${
            isCurrentSort && isAsc
          }}"></i>
          <i class="bx bx-caret-down" :class="{'text-gray-500': ${
            isCurrentSort && !isAsc
          }}"></i>
        </div>
      `;
    },
    changeTableSort(column, defaultDir = "desc") {
      if (column !== this.tableSort.col) {
        this.tableSort.col = column;
        this.tableSort.dir = defaultDir;
      } else {
        this.tableSort.dir = this.tableSort.dir === "asc" ? "desc" : "asc";
      }
    },
  };
}
