// to ensure consistent FE/BE-rendering of user avatars
export function initialsAvatar(user, css_class) {
  if (!user.name) return;
  const initials = toInitials(user.name);

  return `
      <div class="${css_class}">
        <div class='avatar-text-shadow ${bgColor(
          initials[0],
        )} font-medium text-white text-xs h-full w-full flex items-center justify-center'>
          <span>${initials}</span>
        </div>
      </div>
  `;
}

function bgColor(letter) {
  if (["A", "B", "C"].includes(letter)) return "bg-yellow-600";
  if (["D", "E", "F"].includes(letter)) return "bg-purple-600";
  if (["G", "H", "I", "J", "K", "L"].includes(letter)) return "bg-indigo-600";
  if (["M", "N", "O", "P", "Q", "R"].includes(letter)) return "bg-green-600";

  return "bg-pink-600";
}

function toInitials(name) {
  const parsed_name = name.split(" ");

  if (parsed_name.length > 1) {
    return (
      parsed_name[0][0] + parsed_name[parsed_name.length - 1][0]
    ).toUpperCase();
  }

  return parsed_name[0].slice(0, 2).toUpperCase();
}
