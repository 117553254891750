export const formatTime = (seconds, unit = null) => {
  const timeScale = [
    {
      range: [-Infinity, 90],
      scale: 1,
      label: "second",
      abbrev: "s",
      decimals: 0,
    },
    {
      range: [90, 5400],
      scale: 60,
      label: "minute",
      abbrev: "m",
      decimals: 1,
    },
    {
      range: [5400, 172800],
      scale: 3600,
      label: "hour",
      abbrev: "h",
      decimals: 1,
    },
    {
      range: [172800, 1209600],
      scale: 86400,
      label: "day",
      abbrev: "d",
      decimals: 1,
    },
    {
      range: [1209600, 4838400],
      scale: 604800,
      label: "week",
      abbrev: "w",
      decimals: 1,
    },
    {
      range: [4838400, Infinity],
      scale: 2592000,
      label: "month",
      abbrev: "m",
      decimals: 1,
    },
  ];

  // If unit is provided, scale seconds to the provided unit
  if (unit) {
    const scaleItem = timeScale.find(
      (s) => s.label === unit || s.label + "s" === unit,
    );
    if (scaleItem) {
      const value = (seconds / scaleItem.scale).toFixed(scaleItem.decimals);
      const outputUnit = `${scaleItem.label}${value == 1 ? "" : "s"}`;
      return {
        value: parseFloat(value),
        unit: outputUnit,
        abbrev: scaleItem.abbrev,
      };
    }
  }

  // If unit is not provided, scale dynamically
  const scale = timeScale.find(
    (s) => seconds >= s.range[0] && seconds < s.range[1],
  );
  const value = (seconds / scale.scale).toFixed(scale.decimals);
  const outputUnit = `${scale.label}${value == 1 ? "" : "s"}`;
  return {
    value: parseFloat(value),
    unit: outputUnit,
    abbrev: scale.abbrev,
  };
};
