import { pushNotification } from "../pushNotification";

export function userProfile(baseUrl) {
  return {
    user: null,
    loading: false,
    closeProfile() {
      this.user = null;
      this.loading = false;
    },
    copyEmail() {
      navigator.clipboard.writeText(this.user.email).then(() =>
        pushNotification({
          title: "Email copied to clipboard!",
          desc: "Paste it wherever you'd like.",
        }),
      );
    },
    fetchProfile(userId, snapshotId) {
      const url = `${baseUrl}?user_id=${userId}`;

      this.user = null;
      this.loading = true;

      // First fetch the user profile
      fetch(url)
        .then((response) => response.json())
        .then((user) => {
          this.user = user;

          // Then fetch their snapshot request if we have a snapshot ID
          if (snapshotId) {
            return fetch(
              `/snapshot_requests/find_by_snapshot_and_user?user_id=${userId}&snapshot_id=${snapshotId}`,
            )
              .then((response) => {
                if (response.status === 404) {
                  return {
                    exclude: false,
                    snapshot_request_id: null,
                    not_invited: true,
                  };
                }
                return response.json();
              })
              .then((data) => {
                this.user.snapshot_request_id = data.snapshot_request_id;
                this.user.exclude_from_snapshots = data.exclude;
                this.user.not_invited = !!data.not_invited;
                this.user.completed = !!data.completed;
              });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    excludeFromSnapshot() {
      fetch("/snapshot_requests/toggle_exclude", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          snapshot_request_id: this.user.snapshot_request_id,
          snapshot_request: { exclude: true },
        }),
      })
        .then((response) => response.json())
        .then(() => {
          this.user.exclude_from_snapshots = true;
          pushNotification({
            title: "User excluded",
            desc: `${this.user.name} has been excluded from this snapshot.`,
          });
        })
        .catch(() => {
          pushNotification({
            title: "Error",
            desc: "Failed to exclude user. Please try again.",
            type: "error",
          });
        });
    },
    includeInSnapshot() {
      fetch("/snapshot_requests/toggle_exclude", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          snapshot_request_id: this.user.snapshot_request_id,
          snapshot_request: { exclude: false },
        }),
      })
        .then((response) => response.json())
        .then(() => {
          this.user.exclude_from_snapshots = false;
          pushNotification({
            title: "User included",
            desc: `${this.user.name} has been included in this snapshot.`,
          });
        })
        .catch(() => {
          pushNotification({
            title: "Error",
            desc: "Failed to include user. Please try again.",
            type: "error",
          });
        });
    },
  };
}
