import Sortable from "sortablejs";

export function snapshotBranchConfig(args) {
  const { branches, shadowing, accountId } = args;

  return {
    branches,
    shadowing: shadowing,
    editingBranchId: null,
    editingBranchName: "",
    showNewBranchForm: false,
    newBranchName: "",

    startEdit(branch) {
      this.editingBranchId = branch.id;
      this.editingBranchName = branch.name;
    },

    cancelEdit() {
      this.editingBranchId = null;
      this.editingBranchName = "";
    },

    saveBranchName(branch) {
      fetch(`/snapshot_settings/branches/${branch.id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          account_id: accountId,
          name: this.editingBranchName,
        }),
      }).then(() => {
        branch.name = this.editingBranchName;
        this.editingBranchId = null;
        window.pushNotification({
          title: "Success!",
          desc: "Branch name updated.",
        });
      });
    },

    createBranch() {
      fetch("/snapshot_settings/branches", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          account_id: accountId,
          name: this.newBranchName,
        }),
      }).then(() => {
        window.location.reload();
      });
    },

    updateOrder() {
      const order = Array.from(this.$refs.branchList.children).map((el) => {
        return parseInt(el.getAttribute("data-branch-id"));
      });

      fetch("/snapshot_settings/branches/reorder", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          account_id: accountId,
          order,
        }),
      }).then(() => {
        window.pushNotification({
          title: "Success!",
          desc: "Branch order updated.",
        });
      });
    },

    init() {
      new Sortable(this.$refs.branchList, {
        animation: 150,
        handle: ".drag-handle",
        onEnd: () => this.updateOrder(),
      });
    },
  };
}
